@font-face {
font-family: 'marianne';
src: url(/_next/static/media/6c9216284e9b8eb6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: 'marianne';
src: url(/_next/static/media/ee0df43ed5a24add-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: 'marianne';
src: url(/_next/static/media/e4d90019395fec46-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}@font-face {font-family: 'marianne Fallback';src: local("Arial");ascent-override: 103.16%;descent-override: 23.35%;line-gap-override: 0.00%;size-adjust: 109.64%
}.__className_a32572 {font-family: 'marianne', 'marianne Fallback', arial
}

